* {
    box-sizing: border-box;
  }
  
  .main-container{
    max-height: 100vh;
      max-width: 100vw;
      display: grid;
      grid-template-columns: 16.95rem 1fr;
  }
  
  .sidebar {
    overflow-y:auto;  
    grid-column: 1 / 2;
    background-color: #3498ff;
    display: grid;
    max-height:100vh;
    padding-left: 10px;
  }
  
  .header {
    grid-row: 1 / 2;
    background-color: transparent;
    border: none;
  padding-left: 10px;
  }
  
  .main {
    display: grid;
    grid-template-rows: minmax(5rem,50px) 1fr;
    padding-top: 10px;
  } 
  
  .nav-item{
    padding: 28px 25px;
      color: #25265F;
      font-family: "Open Sans";
      font-size: 15px;
      font-weight: 600;
      line-height: 14px;
  }
  .navbar{
    z-index: 100;
    max-height: 5rem;
  }
  .content {
    display: grid;
    height:calc(100vh - 5rem);
    overflow:auto;
    width: calc(100vw - 16.95rem);
  
  }

  .Subbody{
    padding: 0px 10px 0px 10px;
    display: flex;
  }

  @media screen and (max-width: 700px) {
  .content{
    display: grid;
    overflow: auto;
    width: 100vw;
  }
  .main-container {
    max-width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
  }
  .sidebar {
  display:none;
  }
  }

  .rs-form-inline>*, .rs-form-inline>.rs-btn {
    margin-right: 20px;
    margin-bottom: 15px;
    vertical-align: top;
}