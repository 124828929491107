.Button {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    background-color: #0D5463;
    padding: 8px 16px;
    border-radius: 8px;
    color: white;
    transition: all 150ms ease;
    cursor: pointer;
    border: 1px solid #0D5463;
    box-shadow: 0 2px 4px "rgba(13, 84, 99, 0.5)", inset 0 1.5px 1px #1F94AD, inset 0 -2px 1px #094855;
}

.Button:hover {
    background-color: #094855;
}

.Button:active {
    background-color: #063C47;
    box-shadow: none;
}

.Button:focus-visible {
    box-shadow: 0 0 0 4px #99D8E5;
    outline: none;
}